import * as React from "react";
import "./SectionOne.scss";
import PrincipalImage from "../../images/illustrations/contents.administration.png";
import NewsIcon from "../../images/icons/news.inline.svg";
import BenefitsIcon from "../../images/icons/benefits.inline.svg";
import InfoIcon from "../../images/icons/info.inline.svg";
import GalleryIcon from "../../images/icons/gallery.inline.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SectionOne = () => {
	return (
		<div className="section-one" id="solutions">
			<div className="circle lg-circle">
				<div className="circle sm-circle"></div>
			</div>
			<div className="title">
				<h2>
        Mejora la <label>colaboración</label> y <label>comunicación</label> en
     el equipo
				</h2>
			</div>
			<div className="subtitle">
				<h3>
					Revisa las novedades. Entérate de todo. Disfruta las experiencias.
				</h3>
			</div>
			<div className="feature-specifications">
				<div className="left">
					<div className="feature">
						<div className="image">
							<NewsIcon />
						</div>
						<div className="text">
							<label>Noticias</label>
							<p>
								Mejora la comunicación manteniendo informado a tus colaboradores
								con las últimas novedades de tu empresa
							</p>
						</div>
					</div>
					<div className="feature">
						<div className="image">
							<BenefitsIcon />
						</div>
						<div className="text">
							<label>Beneficios</label>
							<p>
								Mejora la retención de talentos compartiendo los beneficios de
								una forma fácil y clara
							</p>
						</div>
					</div>
				</div>
				<div className="middle">
					<LazyLoadImage alt="imagen 1" src={PrincipalImage} effect="blur" />
				</div>
				<div className="right">
					<div className="feature">
						<div className="image">
							<InfoIcon />
						</div>
						<div className="text">
							<label>Info Útil</label>
							<p>
								Accede de forma rápida, tené siempre cerca toda la información
								que necesitan tus colaboradores
							</p>
						</div>
					</div>
					<div className="feature">
						<div className="image">
							<GalleryIcon />
						</div>
						<div className="text">
							<label>Galerías</label>
							<p>Compartí las experiencias y momentos con toda tu empresa</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionOne;
