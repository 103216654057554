import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import HeaderIllustration from "../../images/illustrations/header.illustration.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as React from "react";
import "./Hero.scss";

const Hero = () => {
  return (
    <div className="hero-wrapper">
      <section className="hero" id="hero">
        <div
          className="content"
          //data-sal="slide-right"
          //data-sal-easing="ease"
          //data-sal-duration="400"
        >
          {/*
              <p className="principal">
                PLATAFORMA DE COMUNICACIÓN Y PROCESOS PARA EMPRESAS
              </p>            
            */}

					<h1 className="title">
						¿Necesitás mejorar la <b>comunicación</b> con los <b>equipos</b>{" "}
						dentro de tu <b>empresa</b>?
					</h1>
					<p className="subtitle">
						Nuestra plataforma te permitirá agilizar la comunicación y la
						colaboración entre los equipos y simplificar la gestión de tareas
						mediante el acceso a una amplia gama de herramientas y recursos.
					</p>
					<Link to="/#contact" className="btn-cta">
						Comunicate con nosotros
					</Link>
				</div>
				<div className="images">
					<LazyLoadImage
						alt="Pantalla web mostrando interfaz de Qavant"
						src={HeaderIllustration}
            effect="blur"
					/>
				</div>
			</section>
		</div>
	);
};

export default Hero;
