import axios from "axios";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import "./ContactForm.scss";
import { navigate } from "gatsby";
import CloseButton from "../../images/icons/close-form.inline.svg";

const initialState = {
  name: "",
  company: "",
  email: "",
  referal: "",
  phone: "",
  country: "",
  collaborators: "",
  comments: "",
  errorMessage: "",
};

const formReducer = (state, { type, payload }) => {
  switch (type) {
    case "HANDLE INPUT TEXT":
      return { ...state, [payload.key]: payload.value };
    case "SET ERROR MESSAGE":
      return { ...state, errorMessage: payload };
    case "RESET FORM":
      return initialState;

    default:
      return state;
  }
};

const ContactForm = () => {
  const [formState, dispatch] = React.useReducer(formReducer, initialState);
  const [status, setStatus] = React.useState("idle");

  React.useEffect(() => {
    if (status === "success" || status === "error") {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else if (status === "idle") {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [status]);

  const handleClose = () => {
    setStatus("idle");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      !formState.name ||
      !formState.company ||
      !formState.country ||
      !formState.phone ||
      !formState.email ||
      !formState.collaborators ||
      !formState.referal
    ) {
      handleSetError(
        "Debe completar todos los campos para completar la operación."
      );
    } else if (!formState.email.match(mailformat)) {
      handleSetError(
        "Debe ingresar un correo electrónico válido para completar la operación."
      );
    } else if (formState.collaborators <= 0) {
      handleSetError(
        "La cantidad de colaboradores no puede ser inferior a uno."
      );
    } else {
      setStatus("pending");
      try {
        axios.post("https://api-landing.qavant.com/email/QAVANT/send", {
          Name: formState.name,
          Company: formState.company,
          Country: formState.country,
          Telephone: formState.phone,
          Comments: formState.comments,
          Email: formState.email,
          Collaborators: formState.collaborators,
          referal: formState.referal,
        });

        setStatus("success");
        resetForm();

        navigate("/message?result=ok");
        return null;
      } catch (error) {
        console.log(error);
        setStatus("error");
        resetForm();
      }
    }
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "HANDLE INPUT TEXT",
      payload: { key: e.target.name, value: e.target.value },
    });
  };
  const handleSetError = (message) => {
    dispatch({ type: "SET ERROR MESSAGE", payload: message });
  };
  const resetForm = () => {
    dispatch({ type: "RESET FORM" });
  };

  return (
    <section className="contact-form-wrapper" id="contact">
      <div className="contact-form">
        <div
          className="left"
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-duration="400"
        >
          <p>Completá el formulario para solicitar información</p>
          <StaticImage
            alt="Cuatro personas conversando"
            src="../../images/illustrations/illustration6.svg"
            layout="constrained"
            loading="lazy"
          />{" "}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div className="column">
              <label htmlFor="name">
                Nombre
                <input
                  onChange={handleInputChange}
                  value={formState.name}
                  type="text"
                  name="name"
                  id="name"
                  required
                />
              </label>
              <label htmlFor="company">
                Empresa
                <input
                  onChange={handleInputChange}
                  value={formState.company}
                  type="text"
                  name="company"
                  id="company"
                  required
                />
              </label>
              <label htmlFor="email">
                Correo electrónico
                <input
                  onChange={handleInputChange}
                  value={formState.email}
                  type="email"
                  name="email"
                  id="email"
                  required
                />
              </label>
              <label htmlFor="comments" className="comments">
                Comentarios
                <textarea
                  onChange={handleInputChange}
                  value={formState.comments}
                  name="comments"
                  id="comments"
                />
              </label>
            </div>{" "}
            <div className="column">
              <label htmlFor="phone">
                Teléfono
                <input
                  onChange={handleInputChange}
                  value={formState.phone}
                  type="tel"
                  name="phone"
                  id="phone"
                  required
                />
              </label>
              <label htmlFor="country">
                País
                <input
                  onChange={handleInputChange}
                  value={formState.country}
                  type="text"
                  name="country"
                  id="country"
                  required
                />
              </label>
              <label htmlFor="collaborators">
                Cantidad de colaboradores
                <input
                  onChange={handleInputChange}
                  value={formState.collaborators}
                  type="number"
                  name="collaborators"
                  id="collaborators"
                  min="1"
                  required
                />
              </label>
              <label htmlFor="referal">
                Como nos conociste
                <select
                  onChange={handleInputChange}
                  value={formState.referal}
                  type="referal"
                  name="referal"
                  id="referal"
                  required
                >
                  <option value="" disabled hidden>
                    Elija una opción
                  </option>
                  <option value="Linkedin">Linkedin</option>
                  <option value="Mailing">Mailing</option>
                  <option value="Google">Google</option>
                  <option value="Referido">Referido</option>
                  <option value="Web">Web</option>
                  <option value="Otros">Otros</option>
                </select>
              </label>
            </div>
          </div>
          {formState.errorMessage && (
            <p className="error-message">{formState.errorMessage}</p>
          )}
          <button
            type="submit"
            disabled={status === "pending" || status === "error"}
          >
            {status === "pending" ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              "Enviar"
            )}
          </button>
        </form>
      </div>
      {status === "error" && (
        <div className="backdrop">
          <div className="status-modal-container">
            <CloseButton
              role="button"
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            />
            <StaticImage
              src={"../../images/illustrations/error-illustration.svg"}
              className="status-modal-illustration"
              alt="icono de error"
              loading="lazy"
            />
            <p className="thanks">¡Ha ocurrido un error!</p>
            <p className="text">
              Lo sentimos, por favor comuníquese con nuestros representantes por
              las demás vías de contacto detalladas.
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default ContactForm;
