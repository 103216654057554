import * as React from "react";
import "./SectionTwo.scss";
import PrincipalImage from "../../images/illustrations/documents.administration.png";
import FormsIcon from "../../images/icons/forms.inline.svg";
import DocumentsIcon from "../../images/icons/documents.inline.svg";
import SurveysIcon from "../../images/icons/surveys.inline.svg";
import CoursesIcon from "../../images/icons/courses.inline.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SectionTwo = () => {
	return (
		<div className="section-two">
			<div className="dots"></div>
			<div className="circle lg-circle">
				<div className="circle sm-circle"></div>
			</div>
			<div className="title">
				<h2>
					Gestiona la <label>documentación</label> y agiliza los{" "}
					<label>procesos</label>
				</h2>
			</div>
			<div className="subtitle">
				<h3>
					Agiliza el papeleo. Gestiona la documentación. Aprendizaje constante.
				</h3>
			</div>
			<div className="feature-specifications">
				<div className="left">
					<div className="feature">
						<div className="image">
							<FormsIcon />
						</div>
						<div className="text">
							<label>Formularios</label>
							<p>
								Evita papeleo, agiliza tus procesos por medio del uso de
								formularios digitales
							</p>
						</div>
					</div>
					<div className="feature">
						<div className="image">
							<DocumentsIcon />
						</div>
						<div className="text">
							<label>Documentos</label>
							<p>
								Tené siempre al alcance los documentos importantes para tu día a
								día, con la posibilidad de seguimiento de lectura
							</p>
						</div>
					</div>
				</div>
				<div className="middle">
					<LazyLoadImage
						alt="imagen 1"
						src={PrincipalImage}
						effect="blur"
					/>
				</div>
				<div className="right">
					<div className="feature">
						<div className="image">
							<SurveysIcon />
						</div>
						<div className="text">
							<label>Encuestas</label>
							<p>
								¿Querés saber la opinión de los talentos? Armá encuestas
								personalizadas para relevar distintos aspectos de tu empresa
							</p>
						</div>
					</div>
					<div className="feature">
						<div className="image">
							<CoursesIcon />
						</div>
						<div className="text">
							<label>Capacitaciones</label>
							<p>
								¿Necesitás mantener actualizados a tus colaboradores? Crea
								capacitaciones de distintos tipos
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionTwo;
