import * as React from "react";
import "./SectionFive.scss";
import PrincipalImage from "../../images/illustrations/qavant.devices.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SectionFive = () => {
	return (
		<div className="section-five">
			<div className="circle lg-circle">
				<div className="circle sm-circle"></div>
			</div>
			<div className="image">
				<LazyLoadImage alt="imagen 1" src={PrincipalImage} effect="blur" />
			</div>
			<div className="text">
				<div className="principal">
					<h3>
						Tu mejor <b>solución</b> presente en <b>múltiples dispositivos</b>
					</h3>
				</div>
				<div className="secondary">
					<p>Encontranos en iOS, Android y Desktop Web.</p>
				</div>
			</div>
		</div>
	);
};

export default SectionFive;
